<template>
  <div v-if="$mq === 'sm'">
    <div class="sorry">
      <p>Sorry, you cannot use the course builder on mobile. Please log in on your computer to build courses.</p>
      <div class="button"><div class="primary-btn" @click="router.push('/')">Back</div></div>
    </div>
  </div>
  <div v-else class="createCourse">
    <h1>What type of course is this?</h1>
    <div class="types">
      <div class="type">
        <VirtualClassroomIcon class="icon" />
        <h1>Virtual Classroom</h1>
        <h2>100% online-only</h2>
        <div class="primary-btn" @click="$router.push('/create-course/virtual-classroom')">Create New Classroom</div>
      </div>
      <div class="type">
        <ModularTrainingToolkitIcon class="icon" />
        <h1>Modular Training Toolkit</h1>
        <h2>Supplement to in-person training</h2>
        <div class="primary-btn" @click="$router.push('/create-course/toolkit')">Create New Toolkit</div>
      </div>
    </div>
  </div>
</template>

<script>
import VirtualClassroomIcon from '@/assets/icons/virtual_classroom.svg';
import ModularTrainingToolkitIcon from '@/assets/icons/modular_training_toolkit.svg';

export default {
  components: {
    VirtualClassroomIcon,
    ModularTrainingToolkitIcon,
  },
};
</script>

<style lang="scss" scoped>
.createCourse {
  min-height: calc(100vh - 220px) !important;
  padding: 40px 67px 0px 67px;
  // min-height: calc(100vh - 281px);

  // min-height: calc(100vh - 246px);
  // min-height: calc(100vh - 140px);
  @include lg {
    min-height: calc(100vh - 281px) !important;
  }
  > h1 {
    font-family: $head-font-stack;
    font-weight: 300;
    font-size: 2rem;
    text-align: center;
    margin: 0 0 25px 0;
  }
  > .types {
    width: 635px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    > .type {
      width: 305px;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.29);
      min-height: 219px;
      text-align: center;
      > .icon {
        height: 45px;
        margin-bottom: 10px;
        margin-top: 25px;
      }
      > h1 {
        font-family: $head-font-stack;
        font-weight: 700;
        font-size: 1.8rem;
        margin: 0 0 5px 0;
      }
      > h2 {
        font-family: $head-font-stack;
        font-weight: 300;
        font-size: 1.4rem;
        margin: 0 0 25px 0;
      }
      > .primary-btn {
        width: 225px;
        font-size: 1.6rem;
        margin: 0 auto;
      }
    }
  }
}
.sorry {
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  p {
    width: 76%;
    max-width: 315px;
    // height: 80vh;
    display: flex;
    align-items: center;
    padding: 0;
    font-weight: 300;
    font-size: 28px;
    width: 80%;
    margin: 0 auto;
    flex: 3;
  }
  .button {
    flex: 1;
    width: 100%;
    display: flex;
    // align-items: center;
    .primary-btn {
      margin: auto auto 50px auto;
      flex: 1;
      width: 76%;
      max-width: 315px;
      text-align: center;
    }
  }
}
</style>
